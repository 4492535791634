<template>
  <v-container
      fluid
      style="height:100%;margin-bottom:80px"
      :style="{backgroundColor:'#CDDEFF'}">
  <div
      class="mx-auto"
      :style="{backgroundColor:'#CDDEFF'}"
    >
    <!-- rangkap desktop -->
    <!--Desktop v-show artinya, bakal nampilkan semua rangkap element kodingan ini khusus untuk device == Desktop -->
  <v-card v-show="device == 'Desktop' " max-width="1080" elevation="6" :class="device == 'Desktop' ? 'cardview mx-auto mt-4  rounded' : 'cardview mx-auto  mt-n3'">
    <span class="ml-3 view"><b>view :</b></span>
    <v-chip class="ml-1 personal" @click="HistoryPersonal">Personal</v-chip>
    <v-chip class="personal" v-show="user.level > 2" @click="HistoryAll">Approval</v-chip>
    <v-chip class="personal" v-show="user.level > 1 && user.level < 5" @click="Return">Return</v-chip>
      <v-toolbar
        color="#a10115"
        dark
      >
        <v-toolbar-title>History {{filter}}</v-toolbar-title>
      </v-toolbar>
      <v-list-item-group
          color="primary"
        >
      <v-list three-line>
        <div v-for="(item, index) in items" :key="index">
          <v-subheader
            :key="index"
            v-text="item.date"
            class="tgl mt-n4 mb-n4 mr-15"
          ></v-subheader>
  
          <v-list-item
          @click="goTo(item)"
          v-for="(data,i) in item.data" :key="i+'a'"
          :class="device == 'Desktop' ? 'ml-16' : 'ml-1'">
            <v-list-item-avatar>
             <v-icon>{{data.avatar}}</v-icon>
            </v-list-item-avatar>
  
            <v-list-item-content>
              <v-list-item-title><span class="item-caption">{{data.title}}</span></v-list-item-title>
              <v-list-item-subtitle>
                <span v-text="data.penanggung_jawab+' | '+data.department.nama_department"/><b>
                  <v-chip><span style="color:green" v-text="' | '+data.accs+' | '+data.acc_hr+' | '+data.acc_manager"/></v-chip></b></v-list-item-subtitle>
              <v-list-item-title v-show="filter != 'Return'"><span class="ket"><b>{{data.keterangan == null ? 'Keterangan : -' : 'Keterangan : '+data.keterangan}}</b></span></v-list-item-title>
            </v-list-item-content>
            <v-subheader
            v-text="data.jam_keluar_kendaraan"></v-subheader>
          </v-list-item>
          <v-divider class="mb-2"/>
        </div>
      </v-list>
      </v-list-item-group>
      
  </v-card>
  <!-- akhir rangkap desktop -->
  
  <!-- ini Mobile -->
    <v-card class="cardview" v-show="device == 'Mobile' ">
      <span class="ml-3 view"><b>view :</b></span>
    <v-chip class="ml-1 personal" @click="HistoryPersonal">Personal</v-chip>
    <v-chip class="personal" v-show="user.level > 2" @click="HistoryAll">Approval</v-chip>
    <v-chip class="personal" v-show="user.level > 1 && user.level < 5" @click="Return">Return</v-chip>
    <v-toolbar
        color="#a10115"
        dark
      >
        <v-toolbar-title class="histori">History {{filter}}</v-toolbar-title>
      </v-toolbar>
      
      <v-list-item-group
          color="primary"
        >
      <v-list three-line>
        <div v-for="(item, index) in items" :key="index">
          
          <v-chip label class="chiptgl">
          <v-subheader
            :key="index"
            v-text="item.date"
            class="tgl mt-n4 mb-n4 ml-n5"
          ></v-subheader>
        </v-chip>

          <v-list-item
          @click="goTo(item)"
          v-for="(data,i) in item.data" :key="i+'a'"
          :class="device == 'Desktop' ? 'ml-16' : 'ml-1'">
            <v-list-item-avatar height="45" width="85" class="mt-10 mr-n1 ml-n4">
              <v-row dense>
                <v-col cols="12">
             <v-icon>{{data.avatar}}</v-icon>
            </v-col>
            <v-col cols="12" class="mt-n2">
              <span style="font-size:x-small">{{data.state}}</span>
            </v-col>
            </v-row>
            </v-list-item-avatar>
            
            <v-list-item-content>
              <v-chip label class="chiptgl" style="position: absolute;right:6px;padding:5px;top: 28px;height: 20px;">
              <v-list-item-subtitle><span  class="jam">{{data.jam_keluar_kendaraan}}</span></v-list-item-subtitle>
              </v-chip>
              <v-list-item-subtitle><span class="nama" v-text="data.penanggung_jawab"/><v-divider style="width: 195px;"/></v-list-item-subtitle>
              <v-list-item-title class="mt-n1">
                <span class="item-caption nama_aset">{{data.nama_aset}}</span> 
                <span class="item-caption plat">{{data.plat}}</span>
                <v-divider style="width: 195px;"/>
              </v-list-item-title>
              
              <v-list-item-subtitle>
                <v-icon>mdi-info</v-icon>
                
                <span class="mr-1">
                  <!-- tetapkan accs jadi icon -->
                  <!-- proses hr -->
                  <span class="prosesapprov mr-1" v-show="data.accs == 'process' && data.acc_hr == null">
                    <v-icon small class="prosesapprov mb-1">mdi-backup-restore</v-icon>
                    Proses
                  </span>
                  <!-- end of proses hr -->
                  <v-icon small class="approval mr-1" v-show="data.accs == 'ACC'">mdi-check-decagram</v-icon>
                  <v-icon small class="approval" v-show="data.accs == 'REJECT'">mdi-close</v-icon>
                  <!-- <span class="approval" style="color:black" v-text="' | '+data.accs+' | '+data.acc_hr"/> -->
                  <v-icon small class="approval mr-1" v-show="(data.acc_hr != null && data.accs != 'REJECT') && data.acc_manager == null">mdi-check-decagram</v-icon>
                  <span class="approval" style="color:black" v-text="data.acc_hr"></span>
                 
                  <!-- proses manager -->
                  <span class="prosesapprov mr-1 ml-1" v-show="data.accs == 'process' && data.acc_manager == null && data.acc_hr != null">
                    <v-icon small class="prosesapprov mb-1">mdi-backup-restore</v-icon>
                    Proses
                  </span>
                  <!-- end of proses manager -->
                </span>

                <span v-show="filter != 'Return' && data.acc_manager != null">
                  <!-- tetapkan accs jadi icon -->
                  <v-icon small v-show="data.accs == 'process'">Proses</v-icon>
                  <v-icon small class="approval mr-1" v-show="data.accs == 'ACC'">mdi-check-decagram</v-icon>
                  <v-icon small class="approval" v-show="data.accs == 'REJECT' && data.acc_manager != null">mdi-close</v-icon>
                  <!-- hapus accs dari v-text ohh -->
                  <span class="approval" style="color:black" v-text="data.acc_manager"/>
                </span>
              </v-list-item-subtitle>
                 <v-list-item-subtitle v-show="filter != 'Return'"><span class="ketmo">{{data.keterangan == null ? 'Keterangan : -' : 'Keterangan : '+data.keterangan}}</span></v-list-item-subtitle>
            </v-list-item-content>
  
          </v-list-item>
          <v-divider class="mb-2"/>
        </div>
      </v-list>
      </v-list-item-group>
    </v-card>
    <!-- end of mobile -->
    <!-- </v-card> -->
    </div>
    </v-container>
  </template>
  <script>
  import API from "@/services/http";
    export default {
      data: () => ({
        items: [
        ],
        device:null,
        user:JSON.parse(localStorage.getItem('logged')),
        filter:'Personal',
        peminjaman:[],
        tesdrive:[],
      }),
      beforeMount(){
          this.device = this.$device
      },
      mounted(){
        this.loadHistory()
      },
      methods:{
        loadHistory(){
          this.items = []
          let user = JSON.parse(localStorage.getItem('logged'))
          let state = user.level == 4 ? 'acc_hr' : user.level == 5 ? 'acc_manager' : null
          let department = user.department.id_department
          this.$loading(true)
          API.get('seen/history/'+user.id_user)
          if(this.filter != 'Return'){
          let directpeminjaman = this.filter == 'Personal' ? "/historypeminjaman/"+user.nama_lengkap+'/'+department : '/allhistorypeminjaman/'+user.id_user+'/'+state
          API.get(directpeminjaman).then(x=>{
            x.data.forEach(a => {
              a.avatar = a.keperluan == 'Daily' ? 'mdi-car' : 'mdi-car-key',
              a.state = 'Peminjaman'
              a.title = a.aset.jenis_aset+' '+a.aset.nama_aset+' '+a.aset.no_plat
              a.nama_aset = a.aset.nama_aset
              a.plat = a.aset.no_plat
              a.accs = a.approve_peminjaman != null ? a.approve_peminjaman : 'process'
            });
            this.peminjaman = x.data.reverse()
          })
          let directtesdrive = this.filter == 'Personal' ? "/historytesdrive/"+user.nama_lengkap+'/'+department : "/allhistorytesdrive/"+user.id_user+'/'+state
          API.get(directtesdrive).then(x=>{
            x.data.forEach(a => {
              a.avatar = 'mdi-car-traction-control'
              a.state = 'Tes Drive'
              a.title = a.aset.jenis_aset+' '+a.aset.nama_aset+' '+a.aset.no_plat
              a.nama_aset = a.aset.nama_aset
              a.plat = a.aset.no_plat
              a.accs = a.approve_form_tes_drive != null ? a.approve_form_tes_drive : 'process'
            });
            console.log(x)
            this.tesdrive = x.data.reverse()
            this.items = [...this.peminjaman,...this.tesdrive]
            this.items.sort(function(a,b){
              return new Date(b.tgl_peminjaman) - new Date(a.tanggal_pemakaian);
            });
            this.items.forEach(q => {
              q.Tgl = q.tanggal_pemakaian ? this.$DateConvert(q.tanggal_pemakaian) : this.$DateConvert(q.tgl_peminjaman)
            });
            // this gives an object with dates as keys
              const groups = this.items.reduce((groups, list) => {
                const date = list.Tgl
                if (!groups[date]) {
                  groups[date] = [];
                }
                groups[date].push(list);
                return groups;
              }, {});
    
              // Edit: to add it in the array format instead
              const groupArrays = Object.keys(groups).map((date) => {
                return {
                  date,
                  data: groups[date]
                };
              });
            this.items = groupArrays
            this.$loading(false)
          })
          }else{
           // pengembalian atau return
          API.get('allhistorypengembalianpeminjaman').then(x=>{
            x.data.forEach(a => {
              a.avatar = 'mdi-car'
               a.avatar = a.peminjaman.keperluan == 'Daily' ? 'mdi-car' : 'mdi-car-key',
               a.state = 'Peminjaman'
               a.penanggung_jawab = a.peminjaman.penanggung_jawab
               a.title = a.peminjaman.aset.jenis_aset+' '+a.peminjaman.aset.nama_aset+' '+a.peminjaman.aset.no_plat
               a.accs = a.approve_pengembalian_peminjaman != null ? a.approve_pengembalian_peminjaman : 'process'
               a.acc_manager = ''
               a.acc_hr = a.acc_hr == null ? 'Menunggu Approve' : a.acc_hr
               a.tanggal_pengembalian = a.tgl_pengembalian
            });
            this.peminjaman = x.data.reverse()
          })
          API.get('allhistorypengembaliantesdrive').then(x=>{
            x.data.forEach(a => {
              a.avatar = 'mdi-car-traction-control'
              a.state = 'Tes Drive'
              a.penanggung_jawab = a.tesdrive.penanggung_jawab
              a.title = a.tesdrive.aset.jenis_aset+' '+a.tesdrive.aset.nama_aset+' '+a.tesdrive.aset.no_plat
              a.acc_manager = ''
              a.acc_hr = a.acc_hr == null ? 'Menunggu Approve' : a.acc_hr
              a.accs = a.approve_pengembalian_tes_drive != null ? a.tesdrive.approve_form_tes_drive : 'process'
            });
            console.log(x)
            this.tesdrive = x.data.reverse()
            this.items = [...this.peminjaman,...this.tesdrive]
            this.items.sort(function(a,b){
              return new Date(b.tanggal_pengembalian) - new Date(a.tanggal_pengembalian);
            });
            this.items.forEach(q => {
              q.Tgl = q.tanggal_pengembalian ? this.$DateConvert(q.tanggal_pengembalian) : this.$DateConvert(q.tanggal_pengembalian)
            });
            // this gives an object with dates as keys
              const groups = this.items.reduce((groups, list) => {
                const date = list.Tgl
                if (!groups[date]) {
                  groups[date] = [];
                }
                groups[date].push(list);
                return groups;
              }, {});
    
              // Edit: to add it in the array format instead
              const groupArrays = Object.keys(groups).map((date) => {
                return {
                  date,
                  data: groups[date]
                };
              });
            this.items = [...this.items]
            this.items = groupArrays
            this.$loading(false)
          })
          }
        },
        HistoryPersonal(){
          this.filter = 'Personal'
          this.loadHistory()
        },
        HistoryAll(){
          this.filter = 'Approval'
          this.loadHistory()
        },
        Return(){
          this.filter = 'Return'
          this.loadHistory()
        },
        goTo(x){
          if(x.data[0].id_form_tes_drive){
            this.$router.push('/TesDrive/'+x.data[0].id_form_tes_drive)
            sessionStorage.setItem('lastroute','history')
          }
          if(x.data[0].id_form_peminjaman){
            this.$router.push('/Peminjaman/'+x.data[0].id_form_peminjaman)
            sessionStorage.setItem('lastroute','history')
          }
        },
      },
    }
  </script>
  <style>
  .nama_aset{
    color:black;
    font-size: 12px;
  }
  .plat{
    color:black;
    font-weight: bold;
    margin-left: 5px;
    font-size: 12px;
  }
  .tgl{
    font-weight: bold;
    font-family:Arial, Helvetica, sans-serif;
    color: white !important;
    padding-right: 0px;
  }
  .jam{
    font-size: x-small;
    font-weight: bold;
    color: white !important;
  }
  .ketmo{
    font-size: x-small;
    color: #a10115 !important;
  }
  .ket{
    font-size: small;
    color: #a10115 !important;
  }
  .personal{
    color:#FAFF00 !important;
    background-color: #43658B !important;
  }
  .view{
    font-size: small;
    color: white !important;
  }
  .approval{
    font-size:8.5px;
    color: green !important;
  }
  .prosesapprov{
    font-size:8.5px;
    color: #6C737E !important;
  }
  .judul{
    font-size: medium;
  }
  .jenis{
    font-size: small;
  }
  .nama{
    color: black !important;
    font-size: small;
  }
  .depart{
    font-size: x-small;
    color: #43658B !important;
  }
  .histori{
    font-size: medium;
  }
  .cardview{
    background-color: #43658B !important;
  }
  .apvhr{
    color: #789395 !important;
  }
  .apvmg{
    color: #789395 !important;
  }
  .vehicle{
    font-size: 12px;
    color: #a10115;
  }
  .chiptgl{
    background-color: #43658B !important;
  }
  </style>